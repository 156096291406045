import { getImageDimensions, SanityImageDimensions, SanityImageSource } from "@sanity/asset-utils";

// If an image-asset is deleted in sanity studio the image field on the doucment (with alt-text etc) might still exist in the data, but will cause getImageDimensions to throw an error
export function safelyGetImageDimensions(image: SanityImageSource): SanityImageDimensions {
  try {
    return getImageDimensions(image);
  } catch (e) {
    console.error(e);
    return {
      width: 1,
      height: 1,
      aspectRatio: 1,
    };
  }
}
